// feature flags to allow for turning on an off things in the application without a code push

const featureFlags = {
    local: {
        holidayPage: false,
        userPortal: true,
    },
    dev: {
        holidayPage: false,
        userPortal: false,
    },
    production: {
        holidayPage: false,
        userPortal: false,
    },

}

const env = process.env.REACT_APP_BUILD_ENV ? `${process.env.REACT_APP_BUILD_ENV}` : 'dev';
export default featureFlags[env];
