import React, { useEffect }  from "react";
import { Link } from "react-router-dom";
import { Container, Grid, Button, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import ButtonAppBar from '../components/navbar'
import TuneIcon from '@mui/icons-material/Tune';

import CCImg from '../components/ccimg'

//images
import ImgChartingTheCosmos from '../assets/wwm_charting_the_cosmos.jpg';
import ImgCosmicStorm from '../assets/wwm_cosmic_storm.jpg';
import ImgJBGCreating from '../assets/wwm_jbg_creating.jpg';
import ImgOutdoorVibing from '../assets/wwm_outdoor_vibing.jpg';
import ImgSparkleAndShine from '../assets/wwm_sparkle_and_shine.jpg';
import ImgSpinningBowl from '../assets/wwm_spining_bowl.jpg';

const WorkWithMePage = (props) => {
    useEffect(() => {
        document.title = "Cosmic Chord | Work With Me";  
    }, []);
    const handleSiteChange = (href) => {
        window.location.href = href;
    } 
    return (
        <Container disableGutters={true}>
            <ButtonAppBar />
            <Grid container className="pagecontainer">
                <Grid item xs={12} className="wwmsection shtop" >
                    <CCImg class="wwmrightfloater" img={ImgSpinningBowl} altText="Cosmic spinning bowl" />
                    <p><strong>You are</strong> a unique individual who feels there is more to life than what’s on the surface. You’ve been seeking that special something for yourself or those you love, unable to find something that feels like it fits. You’re asking questions about the universe and your place in it, all the while feeling more and more run down from taking care of everyone and everything else.</p>
                    <p>You need a break. You need a way to not feel so run down all the time, without medications, a way to find relief from stress and a rejuvenation at a soul level. A personalized way to be seen and heard. A way to take care of you, that feels both indulgent and simple, to fit into your daily life.</p>
                    <p>You want to feel less alone and trapped in your mind. You want to return to your body, not just return, but feel GOOD - that sense of deeper belonging.</p>
                </Grid>
                <Grid className="wwmsection shps">
                    <CCImg img={ImgCosmicStorm} altText="Cosmic storm" />
                    <p>Sound healing offers a way to directly connect the mind and the body, allowing feelings and emotions to circulate and release. Combined with astrology, it can be a powerful tool attuned to your specific being and your specific needs.</p>
                    <p>Every atom in the universe vibrates at a specific frequency, and each frequency emits a specific tone or sound. I help you realign with the vibrations within you, which helps to change the frequencies around you.</p>
                    <p>Through the power of music and sound, you can supercharge your immune system, repair both physical and emotional issues, and change on a deep cellular level. Using music as medicine helps activate and stimulate the vagus nerve, which is responsible for the regulation of internal organs, and a healthy vagus nerve is scientifically proven to lower levels of anxiety.</p>
                </Grid><Grid className="wwmsection shp multilineblockcontentwithimage">
                    <CCImg class="wwmleftfloater" img={ImgOutdoorVibing} altText="Vibing Outdoors" />
                    <p>The simple act of listening to music can affect your mood and the flow of energy within your body. Amplify this power by using targeted and specific tones made specifically for you.</p>
                    <p>Using your astrological chart, I can create a soundscape made from the tones the cosmos were emitting on the day you were born (or the day of your choosing). Listening to this track has the ability to reawaken and open your mind and body on a deeper level.</p>
                    <p>With the help of external tools like singing bowls, gongs, and tuning forks, I can help you discover where in your body you are holding tension, and release it. It will be an experience as unique as you, every time targeting new areas that arise with each new situation in your life.</p>
                    <p>Want to take a more active approach in your healing journey? Using your own sounds (humming, singing, sighing) can stimulate and impact your blood vessels and improve your breathing.</p>
                </Grid><Grid className="wwmsection sshp">
                    <CCImg class="wwmrightfloater" img={ImgJBGCreating} altText="Creating the Cosmic Chords" />
                    <p className="soloblockcontent">My name is Jessica Brant. I am a certified sound healer and a Scorpio Rising, Pisces Sun, Aquarius moon. As a professionally trained musician, a seeker of knowledge, and ultimately someone who has un - learned and re-awakened to the natural within, I have experienced a sound healing journey that has taken me from disbelief, to finding proof, and ultimately feeling the difference. I’m so excited to help you feel the power and joy of sound healing for yourself. I promise it will be an experience as unique as you!.</p>
                </Grid><Grid className="wwmsection">
                    <h1 className="wwmsectionheader">ASTROLOGICAL SOUNDSCAPES</h1>
                    <h3 className="packagepricing">$75 / session </h3>
                    <p>Looking to expand your knowledge on how astrology affects you personally? A custom made astrological soundscape can be used to meditate, connect you to a deeper awareness of self, or as a tool to remind you of a specific moment in time. To work with the stars:</p>
                    <List>
                        <ListItem>
                            <ListItemAvatar >
                                <Avatar>
                                    <TuneIcon /> 
                                </Avatar> 
                            </ListItemAvatar>
                            Fill out an Initial form with basic information (birth date, time of birth, place of birth) 
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar >
                                <Avatar>
                                    <TuneIcon /> 
                                </Avatar> 
                            </ListItemAvatar>
                            Receive a Follow up email if there are any additional questions
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar >
                                <Avatar>
                                    <TuneIcon /> 
                                </Avatar> 
                            </ListItemAvatar>
                            1-2 Weeks later receive your personalized soundscape track(s) and PDF of information
                        </ListItem>
                    </List>
                    {/* <Button className="pagebuttonitem" component={Link} to="/buynow">Buy Now (Astrological Soundscapes)</Button> */}
                    <a className="pagebuttonitem" href="https://calendly.com/cosmic_chordwj/ccwj-astrology-reading">Buy Now (Astrological Soundscapes Session)</a>
                </Grid>
                <Grid className="wwmsection">
                    <h1 className="wwmsectionheader">SOUND HEALING 1:1 SESSIONS</h1>
                    <CCImg img={ImgSparkleAndShine} altText="Sparkle and Shine" />
                    <h3 className="packagepricing">$125 / session </h3>
                    <p>Book a 1:1 55 minute sound healing session with me as an introduction to what sound healing is and how it can help you. I’ll answer any questions you may have and give a sample of what you can expect of the sound healing plan inclusive. These sessions can be live in person or conducted via Zoom.</p>
                    <List>
                        <ListItem>
                            <ListItemAvatar >
                                <Avatar>
                                    <TuneIcon /> 
                                </Avatar> 
                            </ListItemAvatar>
                            Initial form fill out with basic information</ListItem>
                        <ListItem>
                            <ListItemAvatar >
                                <Avatar>
                                    <TuneIcon /> 
                                </Avatar> 
                            </ListItemAvatar>
                            1:1 session (55 minutes) to discuss sound healing as a concept, answer any questions you may have, and give you a session focused on what was discussed on the form / at the beginning of the session</ListItem>
                        <ListItem>
                            <ListItemAvatar >
                                <Avatar>
                                    <TuneIcon /> 
                                </Avatar> 
                            </ListItemAvatar>
                            Follow up email with additional resource links and recording of session</ListItem>
                    </List>
                    {/* <Button className="pagebuttonitem" component={Link} to="/buynow">Buy Now (Sound Healing 1:1 Session)</Button> */}
                    <a className="pagebuttonitem" href="https://calendly.com/cosmic_chordwj/naked-voice-sound-healing-session-clone">Buy Now (Sound Healing 1:1 Session)</a>
                </Grid>
                <Grid className="wwmsection">
                    <h1 className="wwmsectionheader">ASTROLOGICAL SOUNDSCAPES WITH LIVE CALL</h1>
                    <h3 className="packagepricing">$125 / session </h3>
                    <p>Looking to expand your knowledge on how astrology affects you personally? A custom made astrological soundscape can be used to meditate, connect you to a deeper awareness of self, or as a tool to remind you of a specific moment in time. To work with the stars:</p>
                    <List>
                        <ListItem>
                            <ListItemAvatar >
                                <Avatar>
                                    <TuneIcon /> 
                                </Avatar> 
                            </ListItemAvatar>
                            Fill out an Initial form with basic information (birth date, time of birth, place of birth)
                        </ListItem>
                    </List>
                    <List>
                        <ListItem>
                            <ListItemAvatar >
                                <Avatar>
                                    <TuneIcon /> 
                                </Avatar> 
                            </ListItemAvatar>
                            Receive a Follow up email if there are any additional questions</ListItem>
                    </List>
                    <List>
                        <ListItem>
                            <ListItemAvatar >
                                <Avatar>
                                    <TuneIcon /> 
                                </Avatar> 
                            </ListItemAvatar>
                            1-2 Weeks later receive your personalized soundscape track(s) and PDF of information</ListItem>
                    </List>
                    <List>
                        <ListItem>
                            <ListItemAvatar >
                                <Avatar>
                                    <TuneIcon /> 
                                </Avatar> 
                            </ListItemAvatar>
                            If you’d like a live call to discuss your musical chart in more detail use this booking option!</ListItem>
                    </List>
                    <a className="pagebuttonitem" href="https://calendly.com/cosmic_chordwj/ccwj-astrology-reading-clone">Buy Now (Astrological Soundscapes 1:1 Session)</a>
                </Grid>
                <Grid item xs={12} md={12} sx={{paddingTop:"8%"}}></Grid>
            </Grid>
        </Container>
    )
}

export default WorkWithMePage;