import { React } from "react";
import { Grid, Container } from '@mui/material';


export default function CCImg(props) {
    return (
        <Container disableGutters={true} sx={{paddingBottom:"5%"}}>
            <Grid item xs={12} md={12} className="imgdisplay">
                <img className={(props.class == null) ? "imgitem" :props.class } src={props.img} alt={props.altText} />
            </Grid>
        </Container>
    );
}