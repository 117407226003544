import featureFlags from "./feature-flags";

const config = {
    local: {
        appID: "blah-blah",
        baseUrl: "https://localhost:5000",
        tokenEndpoint: "http://localhost:7100/local/tokens",
        contactUrl: "http://localhost:7100/local/contactreq",
        environmentTag: "local"
    },
    dev: {
        appID: "blah-blah",
        baseUrl: "https://develop.d2e4xmo7217nrr.amplifyapp.com",
        tokenEndpoint: "https://7nstef4vnk.execute-api.us-west-2.amazonaws.com/dev/tokens",
        contactUrl: "https://7nstef4vnk.execute-api.us-west-2.amazonaws.com/dev/contactreq",
        environmentTag: "dev"
    },
    production: {
        appID: "blah-blah",
        baseUrl: "https://www.cosmicchordwithj.com",
        tokenEndpoint: "https://acw7ijsski.execute-api.us-west-2.amazonaws.com/prod/tokens",
        contactUrl: "https://acw7ijsski.execute-api.us-west-2.amazonaws.com/prod/contactreq",
        environmentTag: "Prod"
    },
}

const env = process.env.REACT_APP_BUILD_ENV ? `${process.env.REACT_APP_BUILD_ENV}` : 'dev';

export default {
  ...config[env],
  featureFlags
}