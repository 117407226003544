import axios from 'axios';
import qs from 'qs';

import config from '../config/index.js'

const {
    appID,
    baseUrl,
    tokenEndpoint,
    contactUrl,
    environmentTag,
} = config

export const createContact = async (args) => {
  try {
    // const authToken = window.localStorage.getItem('token')
    // const headers = {
    //   authorization: `Bearer ${authToken}`
    // }
    const headers = {};
    console.log(contactUrl)
    const response = await axios.post(`${contactUrl}`, args, { headers })
    return response.data.created
  } catch (err) {
    return false
    // consider returning an object
  }
}

