import React, { useEffect }  from "react";
import { Container, Grid } from '@mui/material';

//images
import HomeChartImage from '../assets/home_cc_chart.jpg'
import HomeMusicHealing from '../assets/home_cc_musichealinginaction.jpg'
import HomePianoPainted from '../assets/home_cc_piano_painted.jpg'
import CCBanner from '../assets/cc_banner_title.png'
import CCImg from '../components/ccimg'
import MenuAppBar from "../components/navbar";

const HomePage = (props) => {
    useEffect(() => {
        document.title = "Cosmic Chord | Home";  
    }, []);
    return (
        <Container disableGutters={true}>
            <Grid container className="pagecontainer">
                <Grid item xs={12} md={12} float="right">
                    <MenuAppBar />
                </Grid>
                <Grid item xs={12} md={12} className="ccheadersection">
                    <CCImg class="ccbanner" img={CCBanner} altText="Cosmic Cord Banner" />
                </Grid>
                <Grid item xs={12} md={12} >
                    <Grid item xs={12} md={6}>
                    </Grid>
                    <Grid item xs={12} md={6} className="hmpsection" >
                        <CCImg class="hmrightfloater" img={HomeChartImage} altText="Home Chart" />
                        <p>One of the oldest forms of human communication is music. For over 40,000 years it has facilitated and proven the power to connect: to self, to others, and to the world at large. </p>
                    </Grid>
                    <Grid item xs={12} md={6} className="hmpsectionr" >
                        <p>Astrology has been used for 2,000 years as a tool to help us understand ourselves on a deeper level: why we are here, what drives and motivates us. It’s a small way we can feel we have control over our lives.</p>
                        <CCImg class="hmleftfloater" img={HomeMusicHealing} altText="Cosmic Cord Banner" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <p>Every atom in the universe vibrates at a specific frequency, and this frequency creates a tone. Hi, I’m Jessica Brant, and with my musical experience and background I will help facilitate the power of music in every area of your life.</p>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ float: "left",  clear: "right"}}>
                        <p>Using extensive knowledge of music theory and songwriting, I offer individual soundscape creations tailored to you. Additionally, whether you want to know what the planets sounded like the moment of your birth, and how that affects you today or you want to calm the noises in your head and come back home to yourself, I am here to help.</p>
                        <p>I’ll hold space for you as we work together to create a wholly unique experience for you. Ready to take a step into your power?</p>
                    </Grid>
                    <CCImg img={HomePianoPainted} altText="Cosmic Cord Painted Piano" />
                </Grid>
            </Grid>
        </Container>
    )
}

export default HomePage;