import * as React from 'react';
import { IconButton, Grid, Button } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import CopyrightIcon from '@mui/icons-material/Copyright';

const Footer = () => (
  // <Container className="footer" disableGutters={true}>
    <Grid container className="footer" style={{paddingLeft: 0, paddingRight: 0}}>
      <Grid item xs={5} md={3} className="footertext" >
        <p>Cosmic Chord <CopyrightIcon /> 2022-2024</p>
      </Grid>
      <Grid item xs={3} md={5} sx={{display: 'inline-flex'}} >
          <Grid item xs={2} md={2} className="footericon" >
            <IconButton color="primary" aria-label="upload picture" component="span" href="https://www.facebook.com/Cosmic-Chord-With-J-109196815036389">
              <FacebookIcon />
            </IconButton>
          </Grid>
          <Grid item xs={2} md={2} className="footericon" >
            <IconButton color="primary" aria-label="upload picture" component="span" href="https://twitter.com/cosmicchordwith">
              <TwitterIcon />
            </IconButton>
          </Grid>
          <Grid item xs={2} md={1} className="footericon" >
            <IconButton color="primary" aria-label="upload picture" component="a" href="http://instagram.com/cosmicchordwithj">
              <InstagramIcon />
            </IconButton>
          </Grid>
      </Grid>
      <Grid item xs={4} md={4} className="footertext" >
          <Button className="pagebuttonitem" component="a" href="https://cosmicchordwithj.substack.com/">Newsletter subscription</Button>
      </Grid>
    </Grid>
  // </Container>
);

export default Footer;