import React, { useState, useEffect } from "react";
import { Container, Grid, InputLabel, TextareaAutosize, Checkbox } from '@mui/material';
import ButtonAppBar from '../components/navbar'
import TextField from "@mui/material/TextField";
import { FormControl, FormLabel, FormControlLabel } from '@mui/material'
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";

import {
    createContact,
}  from "../utils/api.js"
import { Check } from "@mui/icons-material";

const startingDate = new Date();
const defaultDate = `${(startingDate.getFullYear()-25).toString()}-${("0" + startingDate.getMonth()).slice(-2)}-${("0" + startingDate.getDay()).slice(-2)}`;
const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    notes: "",
    gender: "",
    os: "",
    newsletter: false,
    favoriteNumber: 0,
    package: "none",
    birthday: defaultDate,
    registerUser: "",
};

const contactsMachine = {
    'displayed': {
        RELOAD: 'loading',
        SUBMIT: 'submit'
      
    },
    'loading': {
        FAIL: 'failed',
        LOADED: 'display'
    },
    'display': {
        DISPLAYED: 'displayed'
    },
    'submit': {
        SUBMITTED: 'submitted',
        FAIL: 'failed'
    },
    'submitted': {
        CACHED: 'display',
        DISPLAY: 'displayed',
    },
    'failed': {
        FAILED: 'displayed'
    }
};

const ContactPage = (props) => {
    useEffect(() => {
        document.title = "Cosmic Chord | Contact Us";  
    }, []);
    const [currentState, setCurrentState] = useState('unloaded');
    const [successfulSubmission, setSuccessfulSubmission] = useState(false);
    const [error, setError] = useState();
    const [formValues, setFormValues] = useState(defaultValues);
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormValues({
        ...formValues,
        [name]: value,
      });
    };
    const handleSliderChange = (name) => (e, value) => {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    };
    const clearInputField = (e) => {
      const { name, value } = e.target;
      setFormValues({
        ...formValues,
        [name]: defaultValues[name],
      });
    };
    const handleSubmit = (event) => {
      event.preventDefault();
      console.log(formValues);
      transition({cmd: 'SUBMIT', prevState: 'displayed'}) 
    };
    const handleNewsletterCheckboxChange = (event) => {
        console.log(event.target.checked);
        setFormValues({
            ...formValues,
            newsletter: event.target.checked,
        });
    }
    const handleResetClick = (event) => {
        event.preventDefault();
        transition({ cmd: 'RELOAD', prevState: 'displayed' });
    }
    const saveContactRequest = async () => {

    }
    const transition = (actions) => {
        let action, prevState = '';
        if (typeof actions === 'object') {
        action = actions.cmd
        prevState = actions.prevState
        } else {
        action = actions
        prevState = currentState
        }
        const nextState = contactsMachine[prevState][action]
        if (nextState) {
        command(nextState, actions)
        setCurrentState(nextState)
        }
    }
    const command = async (nextState, action) => {
        switch (nextState) {
        case 'displayed': {
            // initiate load page here
            break
        }
        case 'loading': {
            try {
                setFormValues(defaultValues);
                setError(null);
                transition({ cmd: 'LOADED', prevState: 'loading'});
            } catch (err) {
                console.log(err.message);
                transition({ cmd: 'FAIL', prevState: 'loading', err: err.message });
            }
            break
        }
        case 'display': {
            transition({ cmd: 'DISPLAYED', prevState: 'display' })
            break
        }
        case 'submit': {
            // setup row for edit
            const newContactCreated = await createContact(formValues);
            // console.log(newContactCreated);
            if (newContactCreated) {
                transition({ cmd: 'SUBMITTED', prevState: 'submit' });
            } else {
                transition({ cmd: 'FAIL', prevState: 'submit', err: 'Could not upload submission. Please try again, in an hour.' })
            }
            break;
        }
        case 'submitted': {
            // on success refresh page
            setError(null);
            setSuccessfulSubmission(true);
                transition({ cmd: 'DISPLAY', prevState: 'submitted' });
            break;
        }
        case 'failed': {
            //display the error and ?reload page? / ?close edit row?
            setError(action.err);
            transition({ cmd: 'FAILED', prevState: 'failed'  }) //consider transition('displayed')
            break;
        }
        default:
            setCurrentState('displayed')
            break
        }
    }
    // if (currentState === 'unloaded') {
    //     transition('LOAD')
    // }
    return (
        <Container disableGutters={true}>
            <Grid container className="pagecontainer">
                <ButtonAppBar />
                <Grid item xs={12} md={12} lg={12} className="form-input-section" sx={{ textAlign: "center" }}>
                    <h2>Contact Us</h2>
                </Grid>
                {!successfulSubmission && (
                    <Grid item xs={12} md={12} lg={12}>
                        <form onSubmit={handleSubmit}>
                            <Grid container className="form-input-container" alignItems="center" justify="center" direction="column">
                                <Grid item xs={12} md={12} lg={12} xl={12} className="form-input-section" sx={{ textAlign: "center" }}>
                                    <TextField
                                        id="lastname-input"
                                        name="lastName"
                                        label="Last Name"
                                        type="text"
                                        value={formValues.lastName}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} className="form-input-section" sx={{ textAlign: "center" }}>
                                    <TextField
                                        id="firstname-input"
                                        name="firstName"
                                        label="First Name"
                                        type="text"
                                        value={formValues.firstName}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} className="form-input-section" sx={{ textAlign: "center" }}>
                                    <TextField
                                        id="email-input"
                                        name="email"
                                        label="Email"
                                        type="text"
                                        value={formValues.email}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} className="form-input-section" sx={{ textAlign: "left" }}>
                                    <FormLabel control={<Checkbox id="newsletter-input" name="newsletter" value={formValues.newsletter} onChange={handleNewsletterCheckboxChange} />} label="Subscribe to our newsletter" />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} className="form-input-section" sx={{ textAlign: "center" }}>
                                    <InputLabel>Notes for your J</InputLabel>
                                    <TextareaAutosize
                                        id="notes-input"
                                        name="notes"
                                        label="Notes for your J"
                                        type="text"
                                        value={formValues.notes}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} className="form-input-section" sx={{ textAlign: "center" }}>
                                    <FormControl>
                                        <FormLabel>Gender</FormLabel>
                                        <RadioGroup
                                            name="gender"
                                            value={formValues.gender}
                                            onChange={handleInputChange}
                                            row
                                        >
                                            <FormLabel
                                                key="male"
                                                value="male"
                                                control={<Radio size="small" />}
                                                label="Male"
                                            />
                                            <FormLabel
                                                key="female"
                                                value="female"
                                                control={<Radio size="small" />}
                                                label="Female"
                                            />
                                            <FormLabel
                                                key="other"
                                                value="other"
                                                control={<Radio size="small" />}
                                                label="Other"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormControl>
                                        <TextField
                                            name="birthday"
                                            value={formValues.birthday}
                                            onChange={handleInputChange}
                                            id="date"
                                            label="Birthday"
                                            type="date"
                                            sx={{ width: 220 }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} className="form-input-section" sx={{ textAlign: "center" }}>
                                    <FormControl>
                                        <InputLabel>Operating System</InputLabel>
                                        <Select
                                            name="os"
                                            value={formValues.os}
                                            onChange={handleInputChange}
                                        >
                                            <MenuItem key="mac" value="mac">
                                                Mac
                                            </MenuItem>
                                            <MenuItem key="windows" value="windows">
                                                Windows
                                            </MenuItem>
                                            <MenuItem key="linux " value="linux">
                                                Linux
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} className="form-input-section" sx={{ textAlign: "center" }}>
                                    <FormControl>
                                        <FormLabel>Package Selection</FormLabel>
                                        <RadioGroup
                                            name="package"
                                            value={formValues.package}
                                            onChange={handleInputChange}
                                            row
                                        >
                                            <FormLabel
                                                key="chart"
                                                value="chart"
                                                control={<Radio size="small" />}
                                                label="Astrological Charts"
                                            />
                                            <FormLabel
                                                key="session"
                                                value="session"
                                                control={<Radio size="small" />}
                                                label="1:1 Session"
                                            />
                                            <FormLabel
                                                key="sessions"
                                                value="sessions"
                                                control={<Radio size="small" />}
                                                label="Sessions Workshop"
                                            />
                                            <FormLabel
                                                key="none"
                                                value="none"
                                                control={<Radio size="small" />}
                                                label="None"
                                                defaultChecked
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} className="form-input-section" sx={{ textAlign: "center" }}>
                                    <div style={{ width: "400px" }}>
                                        Favorite Number
                                        <Slider
                                            value={formValues.favoriteNumber}
                                            onChange={handleSliderChange("favoriteNumber")}
                                            defaultValue={1}
                                            step={1}
                                            min={1}
                                            max={1000}
                                            marks={[
                                                {
                                                    value: 1,
                                                    label: "1",
                                                },
                                                {
                                                    value: 100,
                                                    label: "100",
                                                },
                                                {
                                                    value: 500,
                                                    label: "500",
                                                },
                                                {
                                                    value: 750,
                                                    label: "750",
                                                },
                                                {
                                                    value: 1000,
                                                    label: "1000"
                                                }
                                            ]}
                                            valueLabelDisplay="on"
                                        />
                                    </div>
                                </Grid>
                                {error && (
                                    <Grid item xs={12} md={12} className="errorblock" color="warning">
                                        {error}
                                    </Grid>
                                )}
                                <Grid item xs={6} md={3}>
                                    <Button variant="contained" className="menuitem" onClick={handleSubmit}>
                                        Submit
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Button variant="contained" className="menuitem" onClick={handleResetClick} >
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                )}
                {successfulSubmission && (
                    <Grid item xs={12} md={12} lg={12} className="successfulSubmit">
                        Thank you for your Submission. You will be contacted soon.
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12} md={12} sx={{marginTop:"13%"}}></Grid>
        </Container>
    );
}

    export default ContactPage;