import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './index.css';
import './App.css';
import reportWebVitals from './reportWebVitals';
import HomePage  from './pages/HomePage.js'
import AboutPage  from './pages/AboutPage.js'
import ContactPage  from './pages/ContactPage.js'
import WorkWithMePage  from './pages/WorkWithMePage.js'
// import OnlineStorePage from './pages/OnlineStorePage';

import Footer from './components/sfooter'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="buynow" element={<ContactPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="workwithme" element={<WorkWithMePage />} />
        {/* <Route path='onlinestore' element={<OnlineStorePage />} /> */}
      </Routes>
    </BrowserRouter>
    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
